<template>
  <div v-show="(isMobile || isTablet || isIos) && !isPwa">
    <button v-show="!show" class="floating-action-button" @click="open()">
      <feather-icon icon="BellIcon" size="24" />
    </button>
    <div
      v-show="show"
      class="pwa-notify"
      :style="isTablet ? 'top: 0px; right: 0px;' : 'bottom: 0px; right: 0px;'"
    >
      <b-card no-body class="m-0 pwa-card">
        <div class="pwa-close">
          <feather-icon icon="XIcon" size="24" @click="close()"></feather-icon>
        </div>
        <b-card-title class="pwa-card-title p-2">
          Uygulamayı ana ekranınıza ekleyin.
        </b-card-title>
        <b-card-body class="pwa-card-body">
          <div class="d-flex align-items-center">
            <div class="pwa-logo rounded border mr-2">
              <logo />
            </div>
            <p v-if="!isIos" class="mb-0 mt-0">
              Uygulamayı ana ekranınıza ekleyerek daha hızlı ve kolay erişim
              sağlayabilirsiniz.
            </p>
            <p v-else>
              Uygulamayı ana ekranınıza ekleyerek daha hızlı ve kolay erişim
              sağlayabilirsiniz.
              <br />
              {{ isTablet ? "Sağ üstte" : "Altta bulunan" }} "<component
                :is="require('@/assets/images/icons/SVG/ios-share.svg')"
                color="#0E7AFE"
              />" ikonuna tıkladıktan sonra "{{ $t("addToHomeScreen") }}"
              seçeneğini seçiniz.
            </p>
          </div>
          <b-button v-if="!isIos" variant="primary" class="add-home-screen">
            <feather-icon icon="SmartphoneIcon" size="16" />
            <p>{{ $t("addToHomeScreen") }}</p>
          </b-button>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BCardTitle, BCardBody, BButton } from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import Logo from "@/@core/layouts/components/Logo.vue";
import useAppConfig from "@core/app-config/useAppConfig";
export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    FeatherIcon,
    BButton,
    Logo,
  },
  data() {
    return {
      hover: false,
      show: true,
      device: "",
      innerWidth: window.innerWidth,
    };
  },
  computed: {
    isDark() {
      const { skin } = useAppConfig();
      const isDark = skin.value === "dark";
      return isDark;
    },
    userAgent() {
      return window.navigator.userAgent.toLowerCase();
    },
    isMobile() {
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        this.userAgent
      );
    },
    isIos() {
      if (/ipad|iphone|ipod/.test(this.userAgent)) {
        return true;
      } else {
        return !!(
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2 &&
          /MacIntel/.test(navigator.platform)
        );
      }
    },

    isPwa() {
      return ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) =>
          window.matchMedia("(display-mode: " + displayMode + ")").matches
      );
    },
    isTablet() {
      if (/ipad/.test(this.userAgent)) return true;
      else if (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
      )
        return true;
      else if (
        /android/.test(this.userAgent) &&
        window.innerWidth <= 1024 &&
        window.innerWidth >= 768
      )
        return true;
      else return false;
    },
  },

  mounted() {
    document.querySelector(".pwa-logo").addEventListener("mouseenter", () => {
      this.hover = true;
    });
    document.querySelector(".pwa-logo").addEventListener("mouseleave", () => {
      this.hover = false;
    });
    if (!this.isIos) {
      let deferredPrompt;

      window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        // Update UI notify the user they can install the PWA
        this.open();
      });
      var buttonInstall = document.querySelector(".add-home-screen");
      buttonInstall.addEventListener("click", async () => {
        // Hide the app provided install promotion
        close();
        // Show the install prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        const { outcome } = await deferredPrompt.userChoice;
        // Optionally, send analytics event with outcome of user choice
        //   console.log(`User response to the install prompt: ${outcome}`);
        // We've used the prompt, and can't use it again, throw it away
        deferredPrompt = null;
      });

      window.addEventListener("appinstalled", () => {
        // Hide the app-provided install promotion
        hideInstallPromotion();
        // Clear the deferredPrompt so it can be garbage collected
        deferredPrompt = null;
        // Optionally, send analytics event to indicate successful install
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("success"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("installed"),
          },
        });
      });
    }
  },
  methods: {
    close() {
      var card = document.querySelector(".pwa-notify");
      var opacity = 1;
      var interval = setInterval(() => {
        opacity -= 0.05;
        card.style.opacity = opacity;
        if (opacity <= 0) {
          clearInterval(interval);
          this.show = false;
        }
      }, 15);
      //   this.show = false;
    },
    open() {
      var card = document.querySelector(".pwa-notify");
      card.style.display = "block";
      var opacity = 0;
      var interval = setInterval(() => {
        opacity += 0.05;
        card.style.opacity = opacity;
        if (opacity >= 1) {
          clearInterval(interval);
          this.show = true;
        }
      }, 15);
      //   this.show = true;
    },
  },
};
</script>

<style lang="scss">
body {
  &.dark-layout {
    .pwa-card-title,
    .pwa-close {
      background-color: #161d31 !important;
      border-top: 0.5px solid #404656;
      border-left: 0.5px solid #404656;
      border-right: 0.5px solid #404656;
    }
    .pwa-close {
      border-bottom: 0.5px solid #404656;
    }
    .pwa-card-body {
      border-bottom: 0.5px solid #404656;
      border-left: 0.5px solid #404656;
      border-right: 0.5px solid #404656;
    }
    .pwa-logo:hover {
      background: white;
      box-shadow: 0 4px 24px 0 white;
    }
  }
}
.floating-action-button {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 40px;
  background-color: #283046;
  border-radius: 100%;
  border: 0.5px solid #404656;
  color: #f8f8f8;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #283046;
    box-shadow: 0 4px 24px 0 #283046;
  }
}
.pwa-notify {
  position: fixed;
  right: 0;
  z-index: 9999;
  width: 100%;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 12px;
  border-radius: 0.428rem;
  margin-bottom: 10px;

  .pwa-card {
    position: relative;
    z-index: 3;
    .pwa-card-title {
      background-color: #f8f8f8;
      border-top-left-radius: 0.428rem;
      border-top-right-radius: 0.428rem;

      margin-bottom: 0px;
      font-size: 16px;
      padding: 12px 16px !important;
    }

    .pwa-card-body {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px !important;
      border-radius: 0 0 0.428rem 0.428rem;

      .add-home-screen {
        background-color: #f8f8f8;
        border: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        transition: all 0.2s ease;
        margin-left: auto;
        z-index: 9999;
        & p {
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 0.5rem;
          min-width: 130px;
        }

        &:hover {
          background-color: #f8f8f8;
          box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.24);
          transform: translate(2px, -3px);
        }
      }
      > * {
        margin-right: 1rem;
      }

      .pwa-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100% !important;
        padding: 8px;
        transition: all 0.2s ease;
        height: 50px;
        cursor: pointer;
        &:hover {
          background: #f8f8f8;
          box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.24);
        }
      }
    }

    .pwa-close {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -12px;
      margin-right: 5px;
      cursor: pointer;
      font-size: 14px;
      background-color: #f8f8f8;
      border-radius: 0.428rem;
      box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.24);
      padding: 4px;
      &:hover {
        transform: translate(2px, -3px);
        transition: all 0.3s ease;
      }
    }
  }
}
</style>
