import Vue from "vue";

// axios
import axios from "axios";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: "https://api.optitmr.com",
  //baseURL: "https://api.herdoncloud.com",
  // timeout: 1000,
  headers: { "Content-Type": "application/json" },
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
